<script setup>
import { useCurrentUser } from "vuefire";
import { signOut } from "firebase/auth";
import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";

// Injections
const firebaseAuth = inject("firebaseAuth");
const openAuthModal = inject("openAuthModal");

// Composables
const currentUser = useCurrentUser();
const router = useRouter();

// Methods
const onLoginClick = () => {
  openAuthModal();
};

const onLogoutClick = async () => {
  await signOut(firebaseAuth);
  router?.push("/login");
};
</script>

<template>
  <!-- Account dropdown -->
  <Menu as="div" class="ml-3 relative z-10">
    <div class="flex items-center h-full">
      <UiLoader v-if="currentUser === undefined" />

      <UiButton
        v-if="currentUser === null"
        theme="brand-link"
        size="sm"
        @click="onLoginClick"
      >
        <span>Login</span>
      </UiButton>

      <MenuButton
        v-if="currentUser"
        class="max-w-xs flex items-center focus:outline-none rounded-full focus:text-slate-500 hover:text-slate-500 text-slate-400"
      >
        <IconIcRoundAccountCircle class="h-6 w-6 md:h-8 md:w-8" />
      </MenuButton>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <MenuItems
        class="origin-top-right border border-light-100 absolute right-0 mt-2 w-56 rounded-lg shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
      >
        <div v-if="currentUser" class="py-1">
          <div class="px-4 py-3">
            <p class="text-slate-400 text-sm">Signed in as</p>
            <p class="font-medium text-gray-900 truncate">
              <a :href="`/account?ref=${currentUser.uid}`" title="Account Settings">
                {{ currentUser.email }}
                <span
                  class="inline-flex items-center px-1 py-1 rounded-full text-xs font-medium bg-brand-primary text-white"
                >
                  <IconIcRoundStarRate />
                </span>
              </a>
            </p>
          </div>
        </div>
        <div class="py-1">
          <MenuItem>
            <a
              href="/account"
              title="Account Settings"
              class="block px-4 py-2 hover:bg-gray-100 hover:text-gray-900"
            >
              Account Settings
            </a>
          </MenuItem>
          <MenuItem>
            <a
              href="https://www.mixo.io/affiliates"
              title="Earn Money"
              class="block px-4 py-2 hover:bg-gray-100 hover:text-gray-900"
            >
              Earn Money
            </a>
          </MenuItem>
          <MenuItem v-if="currentUser">
            <a
              href="#"
              title="Logout"
              class="block px-4 py-2 hover:bg-gray-100 hover:text-gray-900"
              @click="onLogoutClick"
            >
              Logout
            </a>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>
