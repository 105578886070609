<template>
  <UiCenter>
    <UiLoader :iconColorClass="iconColorClass" :label="label" :delay="delay" />
  </UiCenter>
</template>

<script>
export default {
  name: 'UiLoader',
  props: {
    iconColorClass: {
      type: String,
      default: 'text-brand-primary',
    },
    label: {
      type: String,
      default: 'Loading...',
    },
    delay: {
      type: Number,
      default: 0, // Default delay of 300ms
    },
  },
}
</script>
