<template>
  <div class="absolute top-1.5 [line-height:0] [right:1vw]">
    <Popover v-slot="{ open }" class="relative">
      <PopoverButton
        :class="open ? '' : 'text-opacity-40 lg:text-opacity-60'"
        class="group inline-flex items-center rounded-md bg-slate-100 px-0.5 shadow text-brand-primary focus:ring-brand-primary focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-slate-100/70 focus:bg-slate ring-1 ring-slate-200 hover:ring-brand-primary/40 hover:bg-slate-100 hover:text-opacity-70"
      >
        <IconBiThreeDots class="w-5 h-5" />
      </PopoverButton>

      <transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0"
      >
        <PopoverPanel
          v-slot="{ close }"
          class="absolute right-0 z-10 pt-1 transform pr-0.5 sm:px-0 w-max"
        >
          <div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-slate-300">
            <div
              class="flex flex-col relative gap-2 bg-white p-2 text-sm tracking-normal font-medium"
              @click.stop.prevent
            >
              <!-- Edit Brand -->
              <PopoverButton
                v-if="editBrand"
                class="default-link flex items-center gap-1"
                type="button"
                @click.stop.prevent="onClick('previewFrameEditBrand', close)"
              >
                <IconTablerPhotoEdit class="w-4 h-4" /> Customize Brand
              </PopoverButton>
              
              <!-- Rewrite Content -->
              <PopoverButton
                v-if="rewriteContent"
                type="button"
                class="group flex items-center gap-1"
                title="AI will rewrite this selected content for you"
                @click.stop.prevent="onClick('previewFrameRewriteContent', close)"
              >
                <IconTablerSparkles
                  class="w-4 h-4 text-brand-primary underline group-hover:no-underline group-hover:text-brand-secondary"
                />
                <span
                  class="text-brand-primary underline group-hover:no-underline group-hover:text-brand-secondary"
                >Rewrite Text</span>
                <span
                  class="text-xs bg-brand-alt px-2 rounded-full text-white flex-row-center font-mono font-semibold no-underline group-hover:no-underline"
                >AI</span>
              </PopoverButton>

              <!-- Reselect Image -->
              <PopoverButton
                v-if="reselectImage"
                class="group flex items-center gap-1"
                type="button"
                title="AI will reselect another image for you"
                @click.stop.prevent="onClick('previewFrameReselectImage', close)"
              >
                <IconTablerSparkles
                  class="w-4 h-4 text-brand-primary underline group-hover:no-underline group-hover:text-brand-secondary"
                />
                <span
                  class="text-brand-primary underline group-hover:no-underline group-hover:text-brand-secondary"
                >Reselect Image</span>
                <span
                  class="text-xs bg-brand-alt px-2 rounded-full text-white flex-row-center font-mono font-semibold no-underline group-hover:no-underline"
                >AI</span>
              </PopoverButton>

              <!-- Edit Content -->
              <PopoverButton
                v-if="editContent"
                class="default-link flex items-center gap-1"
                type="button"
                @click.stop.prevent="onClick('previewFrameEditContent', close)"
              >
                <IconTablerEdit class="w-4 h-4" /> Edit Content
              </PopoverButton>
            </div>
          </div>
        </PopoverPanel>
      </transition>
    </Popover>
  </div>
</template>

<script setup>
import postRobot from "post-robot";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";
import { useToast } from "vue-toast-notification";
import { APP_URL } from "~/utils/constants.js";
import { defaultToastOptions } from "@/modules/vue-toast-notification.js";
import { logger } from "@/utils/index.js";

const props = defineProps({
  editContent: {
    type: Boolean,
    default: false,
  },
  reselectImage: {
    type: Boolean,
    default: false,
  },
  editBrand: {
    type: Boolean,
    default: false,
  },
  rewriteContent: {
    type: Boolean,
    default: false,
  },
  mapping: {
    type: Object,
    default: () => ({
      section: "", // used to locate the date in the formkit schema
      field: "", // used to locate the date in the formkit schema
      index: null, // used to locate the data in the formkit schema
      pageDataMapping: null, // used to locate the data in the pageData
    }),
  },
  isUpdated: {
    type: Boolean,
    default: false,
  },
});

// perform some checks
// if editContent is set then mapping must be provided with at least the section set
if (props.editContent && !props.mapping.section) {
  logger.warn(
    "PreviewMenu.vue: If editContent is set to true, then mapping.section must be provided",
    props
  );
}

// if rewriteContent or reselectImage is set then mapping must be provided with pageDataMapping set
if ((props.rewriteContent || props.reselectImage) && !props.mapping.pageDataMapping) {
  logger.warn(
    "PreviewMenu.vue: If rewriteContent or reselectImage is set to true, then mapping.pageDataMapping must be provided",
    props
  );
}

const $toast = useToast(defaultToastOptions);

// Methods
const onClick = (type, close) => {
  postRobot.send(window?.parent, type, props?.mapping, APP_URL).catch((error) => {
    // Handle any errors that stopped our call from going through
    $toast.error("Something went wrong. Please try again.");
    console.error(error);
    console.log(props?.mapping);
  });

  if (close && typeof close === "function") {
    close();
  }
};
</script>

<style lang="postcss">
.preview-menu-wrapper {
  /* Show the menu when the button is hovered on devices with mouse support */
  .preview-menu-container {
    @apply font-mixo;
    @media (hover: hover) and (pointer: fine) {
      @apply hidden;
    }
  }

  &:hover {
    @apply bg-red-100 rounded-lg bg-opacity-30;
    .preview-menu-container {
      @media (hover: hover) and (pointer: fine) {
        @apply block;
      }
    }
  }
}
</style>
