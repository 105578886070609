<template>
  <div
    v-if="!userEmailVerified"
    class="flex items-center justify-center text-center p-1 md:py-3 bg-yellow-100 text-xs sm:text-sm font-medium text-yellow-600 border-b border-yellow-200 "
    data-testid="email-verification"
  >
    <div class="max-w-sm sm:max-w-xl md:max-w-4xl xl:max-w-6xl">
      Verification email sent{{ userEmail ? ` to ${userEmail}` : "" }}. Check your email and click the link to verify account. If already verified, please
      <button type="button" class="text-brand-tertiary underline" @click="onRefreshPage">
        refresh
      </button>
      the page. Didn't receive the email? Check your spam folder or try to
      <button
        type="button"
        class="text-brand-tertiary underline"
        :disabled="sendingEmail"
        @click="onResendClick"
      >
        {{ sendingEmail ? "sending..." : "resend verification email" }}
      </button>.
    </div>
  </div>
</template>

<script setup>
import { sendEmailVerification } from "firebase/auth";
import { firebaseAuth } from "@/modules/firebase.js";
import { useCurrentUser } from "vuefire";
import { isUserEmailVerified } from "@/composables";
import { logger } from "@/utils/index.js";

// Injections
const toast = inject("$toast");

// Composables
const route = useRoute();

// Data
const isPreviewRoute = eagerComputed(
  () => route?.name === "sites-preview" || route?.path === "/sites/preview"
);
const user = useCurrentUser();
const userEmailVerified = eagerComputed(() => {
  // if the url is /sites/preview or contains ai-website-builder, we don't need to check if the user is verified
  if (isPreviewRoute?.value || route?.path.includes("ai-website-builder")) {
    return true;
  }

  // passing in user.value reevaluates the computed when the user changes
  return isUserEmailVerified(user.value);
});
const userEmail = eagerComputed(() => user.value?.email);
const [sendingEmail, toggleSendingEmail] = useToggle(false);

// Methods
const onResendClick = async () => {
  toggleSendingEmail();

  try {
    await sendEmailVerification(firebaseAuth.currentUser);

    toast?.open({
      message: "Please check your email to verify your account 📧",
      type: "success",
      dismissible: true,
    });
  } catch (error) {
    logger.error(error);

    toast?.open({
      message: `Sorry there was an error sending the verification email. Please contact <a href='${
        import.meta.env.VITE_APP_URL
      }/support'>support</a>.`,
      type: "error",
      dismissible: true,
      duration: 0,
    });
  } finally {
    sendingEmail.value = false;
  }
};

const onRefreshPage = () => {
  location.reload();
};
</script>
