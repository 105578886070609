<template>
  <component
    :is="is"
    :to="to"
    class="ui-button"
    :class="classObject"
    :style="styleObject"
    v-bind="$attrs"
    :type="type"
    :disabled="loading || disabled"
    :target="openInNewTab ? '_blank' : undefined"
  >
    <UiLoader
      v-if="loading"
      :textColorClass="loadingClass"
      :iconColorClass="loadingClass"
      :label="loadingLabel"
      :size="size"
      class="!font-semibold"
      :delay="0"
    />

    <iconify-icon
      v-if="!loading && icon?.icon"
      :icon="icon?.icon"
      class="w-auto h-auto"
      :class="iconClasses"
    ></iconify-icon>

    <slot v-if="!loading" />
  </component>
</template>

<script lang="ts">
import "iconify-icon";
import UiLoader from "../UiLoader/UiLoader.vue";

export default {
  components: { UiLoader },
  props: {
    backgroundColor: {
      type: String,
      default: "",
    },
    danger: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    loadingLabel: {
      type: String,
      default: "",
    },
    loadingClass: {
      type: String,
      default: "text-white",
    },
    icon: {
      type: Object,
      default: undefined,
    },
    openInNewTab: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "base",
    },
    textColor: {
      type: String,
      default: "",
    },
    theme: {
      type: String,
      default: "primary",
    },
    to: {
      type: [String, Object],
      default: "",
    },
  },
  data() {
    return {
      loaderClassObject: {
        [`ui-loader-${this.theme}`]: this.theme,
      },
    };
  },
  computed: {
    classObject() {
      return {
        "ui-button": true,
        [`ui-button-${this.size}`]: this.size,
        [`ui-button-${this.theme}`]: this.theme && !this.backgroundColor,
        [`ui-button-${this.loading}`]: this.loading,
        "ui-button-danger": this.danger,
        "hover:scale-[102%]": true,
        "transition-transform": true,
      };
    },
    iconClasses() {
      return {
        currentColor: this.textColor,
        "mr-1": this.size === "xs" || this.size === "sm",
        "mr-2": this.size === "base" || this.size === "md",
        "mr-3": this.size === "lg" || this.size === "xl",
      };
    },
    styleObject() {
      return {
        backgroundColor: this.backgroundColor,
        color: this.textColor,
      };
    },
    is() {
      if (this?.$attrs?.href) return "a";

      if (this?.$props?.to) return "router-link";

      return "button";
    },
    type() {
      if (this?.$attrs?.type) return this?.$attrs?.type;

      if (this?.is === "button") return "button";

      return null;
    },
  },
};
</script>

<style lang="postcss">
.ui-button {
  @apply inline-flex items-center font-semibold tracking-wide font-sans border border-transparent rounded-md focus:outline-none text-center justify-center no-underline space-x-1;

  &:disabled {
    @apply hover:no-underline;
  }
}

/* Sizes */
.ui-button-xs {
  @apply px-2 py-1.5 text-xs;
}

.ui-button-sm {
  @apply px-3 py-2 text-sm;
}

.ui-button-base {
  @apply px-4 py-2 text-base;
}

.ui-button-md {
  @apply px-4 py-2 text-base;
}

.ui-button-lg {
  @apply px-6 py-3 text-lg;
}

.ui-button-xl {
  @apply px-8 py-4 text-xl;
}

/* Themes */
.ui-button-primary {
  @apply text-white !bg-primary shadow hover:!bg-secondary focus:ring-2 focus:ring-primary/75 focus:ring-offset-2 active:shadow-none;

  @apply disabled:!bg-primary/90 disabled:cursor-not-allowed;
}

.ui-button-secondary {
  @apply text-white !bg-secondary shadow border-slate-200 hover:!bg-primary focus:ring-2 focus:ring-offset-2 focus:ring-primary active:shadow-none;

  & .ui-button-danger {
    @apply hover:text-red-700 hover:border-red-700 focus:ring-red-700 focus:text-red-700;
  }
}

.ui-button-tertiary {
  @apply shadow bg-slate-400 text-white focus:ring-2 focus:ring-offset-2 focus:ring-slate-500  hover:bg-slate-500 active:shadow-none;
}

.ui-button-link {
  @apply px-0 text-primary;
  @apply hover:underline hover:text-secondary;

  & .ui-button-danger {
    @apply hover:text-red-700;
  }
}

.ui-button-brand-primary {
  @apply text-white !bg-brand-primary shadow hover:!bg-brand-secondary hover:text-slate-800 focus:ring-2 focus:ring-brand-primary/75 focus:ring-offset-2 active:shadow-none font-semibold;

  @apply disabled:!bg-slate-300 disabled:text-slate-500 disabled:cursor-not-allowed;
}

.ui-button-brand-secondary {
  @apply text-yellow-800 !bg-brand-secondary shadow hover:!bg-brand-primary focus:ring-2 focus:ring-brand-secondary/75 focus:ring-offset-2 active:shadow-none hover:text-white;
}

.ui-button-brand-tertiary {
  @apply text-white !bg-brand-tertiary shadow hover:!bg-brand-secondary focus:ring-2 focus:ring-brand-tertiary/75 focus:ring-offset-2 active:shadow-none hover:text-slate-900;
}

.ui-button-brand-success {
  @apply text-white !bg-emerald-500 shadow hover:!bg-brand-primary focus:ring-2 focus:ring-emerald-500/75 focus:ring-offset-2 active:shadow-none hover:text-white;
}

.ui-button-brand-link {
  @apply px-0 text-brand-primary;
  @apply hover:underline hover:text-brand-secondary;

  & .ui-button-danger {
    @apply hover:text-red-700;
  }
}

.ui-button-brand-link-secondary {
  @apply px-0 text-brand-secondary;
  @apply hover:underline hover:text-brand-primary;

  & .ui-button-danger {
    @apply hover:text-red-700;
  }
}

.ui-button-brand-link-tertiary {
  @apply px-0 text-brand-tertiary;
  @apply hover:underline hover:text-brand-primary;

  & .ui-button-danger {
    @apply hover:text-red-700;
  }
}

.ui-button-outline {
  @apply text-slate-500 !bg-transparent shadow hover:!bg-white focus:ring-2 focus:ring-brand-secondary/75 focus:ring-offset-2 active:shadow-none border-slate-300;
  @apply hover:border-slate-500 hover:text-slate-700;
}

.ui-button-outline-primary {
  @apply text-brand-primary !bg-transparent shadow hover:!bg-white focus:ring-2 focus:ring-brand-primary/75 focus:ring-offset-2 active:shadow-none border-brand-primary;

  &:hover {
    @apply text-white !bg-brand-primary;
  }
}

.ui-button-tertiary {
  @apply text-slate-500 !bg-white shadow hover:!bg-slate-100 focus:ring-2 focus:ring-slate-300/75 focus:ring-offset-2 active:shadow-none border-slate-300;
}
</style>
